import './App.css';
import RouteIndex from './routes/RouteIndex';

function App() {
  return (
    <RouteIndex />
  );
}

export default App;
